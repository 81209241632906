import { HttpWrapper, AppConfig } from "../../../../../core";
import { PostExportModel } from "../../../../../shared/export/post.export.model";
import { CustomConnectionsSemaphoresListResponseModel } from "../models/custom.connections.semaphores.list.response.model";
import { CustomConnectionsSemaphoresDetailModel } from "../models/custom.connections.semaphores.detail.model";
import { EditCustomConnectionSemaphoresModel } from "../models/edit.custom.connection.semaphores.model";
import { CustomConnectionSemaphoresPostModel } from "../models/custom.connection.semaphores.post.model";
import { LinkResourcesResponseModel, DeleteResponseModel } from "../../../../../shared/models";

export class CustomConnectionsSemaphoresService {
    private wrapper: HttpWrapper;
    constructor() {
        this.wrapper = new HttpWrapper();
    }

    getCustomConnectionSemaphoresList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
        return this.wrapper.post<CustomConnectionsSemaphoresListResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }

    getCustomConnectionSemaphoresById(id: number) {
        return this.wrapper.get<CustomConnectionsSemaphoresDetailModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/' + id);
    }
    editCustomConnectionSemaphores(id: number) {
        return this.wrapper.get<EditCustomConnectionSemaphoresModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/edit/' + id);
    }
    postCustomConnectionSemaphores(CustomConnection: CustomConnectionSemaphoresPostModel) {
        return this.wrapper.post<CustomConnectionSemaphoresPostModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores', CustomConnection);
      }

    updateCustomConnectionSemaphores(CustomConnectionSemaphores: CustomConnectionSemaphoresPostModel) {
        return this.wrapper.put<CustomConnectionSemaphoresPostModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/' + CustomConnectionSemaphores.id, CustomConnectionSemaphores);
    }
    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/' + id + '/linked_resources');
    }

    deleteRecordById(id: number) {
        return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/custom_connection_semaphores/' + id);
    } 

    exportCustomConnectionSemaphores(exportData?: PostExportModel) {
        return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/CustomconnectionSemaphores/export', exportData);
    }

}