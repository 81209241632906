import { HttpWrapper, AppConfig } from '../../../../../../core'
import { ExtractionEngineSemaphoresListResponseModel,ExtractionEngineSemaphoresDetailModel,PostExtractionEngineSemaphoresModel,EditExtractionEngineSemaphoresModel } from '../models';
import { PostExportModel } from "../../../../../../shared/export/post.export.model";
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../../../../shared/models';

export class ExtractionEngineSemaphoresService {
    private wrapper: HttpWrapper;

    constructor() {
      this.wrapper = new HttpWrapper();
    }
  
    getList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
      return this.wrapper.post<ExtractionEngineSemaphoresListResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/list?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
    }
  
    getExtractionEngineSemaphoresById(id: number) {
      return this.wrapper.get<ExtractionEngineSemaphoresDetailModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/' + id);
    }
  
    postExtractionEngineSemaphores(payload: PostExtractionEngineSemaphoresModel) {
      return this.wrapper.post<PostExtractionEngineSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores', payload);
    }
  
    editExtractionEngineSemaphores(id: number) {
      return this.wrapper.get<EditExtractionEngineSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/edit/' + id);
    }

    getLinkedResourcesById(id: number) {
        return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/' + id + '/linked_resources');
      }
  
    deleteExtractionEngineSemaphoresById(id: number) {
      return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/' + id);
    }
  
    updateExtractionEngineSemaphores(extraction_engine: PostExtractionEngineSemaphoresModel) {
      return this.wrapper.put<PostExtractionEngineSemaphoresModel>(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/' + extraction_engine.id, extraction_engine);
    }
    
    exportExtractionEngineSemaphores(exportData?: PostExportModel) {
      return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/extraction_engine_semaphores/export', exportData);
    }
  
  
  }