import React, { Component, Fragment } from 'react'
import { LinkResourcesResponseModel, DeleteResponseModel } from '../../../../shared/models'
import { CustomConnectionsSemaphoresService } from '../custom-connections-semaphores/services'
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { CustomConnectionSemaphoresTabs } from './CustomConnectionSemaphoresTabs';
import { NavLink } from "react-router-dom";
import { ButtonType, NotFoundResponseArea } from '../../../../shared/enums';

interface IState {
    result?: LinkResourcesResponseModel;
    deleteResult?: DeleteResponseModel;
    isDoubleleClick?: boolean;
}
export  class DeleteCustomConnectionSemaphores extends Component <any, IState>{
    private service: CustomConnectionsSemaphoresService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new CustomConnectionsSemaphoresService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: ""
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<LinkResourcesResponseModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.CustomConnectionSemaphores,this.props)
            });
    }

    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<DeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Custom Connection Semaphore Successfully Deleted.");
                                this.props.history.push('/custom-connection-semaphore/list');
                            }
                        });
                    }
                })
        }
    }
    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5>Delete Custom Connection Semaphore 'CustomConnectionSemaphore #{this.props.match.params.id}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <CustomConnectionSemaphoresTabs id={this.props.match.params.id} url="/custom-connection-semaphore/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this custom connection semaphore  <strong>“CustomConnectionSemaphore #{this.props.match.params.id}”</strong> ?</div>

                                <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>
                                <div className="row ">
                                    <div className="col">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Custom Connection Semaphore</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light" to={"/custom-connection-semaphore/detail/" + this.props.match.params.id}>Custom Connection Semaphore #{this.props.match.params.id}</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/custom-connection-semaphore/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}
