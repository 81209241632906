export enum NotFoundResponseArea {
    Accounts = "Accounts",
    AccountDomain = "AccountDomain",
    AccountSourceOverrides = "AccountSourceOverrides",
    Connection = "Connection",
    ConnectionSemaphores = "ConnectionSemaphores",
    CustomConnection = "CustomConnection",
    CustomConnectionSemaphores = "CustomConnectionSemaphores",
    InboundFiles = "InboundFiles",
    Platforms = "Platforms",
    Users = "Users",
    Dashboards = "Dashboards",
    Reports = "Reports",
    InstalledApplications = "InstalledApplications",
    ReportChangeHistories = "ReportChangeHistories",
    ReportColumns = "ReportColumns",
    ReportColumnFields = "ReportColumnFields",
    ReportColumnFilters = "ReportColumnFilters",
    ReportFilters = "ReportFilters",
    ReportRunSchedules = "ReportRunSchedules",
    ReportViews = "ReportViews",
    Applets = "Applets",
    SourceReports = "SourceReports",
    StaqContacts = "StaqContacts",
    CustomerFavicon = "CustomerFavicon",
    CustomerLogo = "CustomerLogo",
    DataEngine = "DataEngine",
    Applications = "Applications",    
    DatabaseEngine = "DatabaseEngine",
    InvocationSchedules = "InvocationSchedules",
    Roles = "Roles",
    ExtractionSchedulingRecipesChanges="ExtractionSchedulingRecipesChanges",    
    ExtractionEngine = "ExtractionEngine",
    ExtractionSchedulingRecipes = "ExtractionSchedulingRecipes",
    DataEngineJob = "DataEngineJob",
    InboundEmailAddresses = "InboundEmailAddresses",
    Schemas = "Schemas",
    Fields = "Fields",
    FieldsClassification = "FieldsClassification",
    Scopes="Scopes",    
    InboundEmailMessages = "InboundEmailMessages",
    TagLocationCategory = "TagLocationCategory",
    TagMatchers = "TagMatchers",    
    ScopeSchemaChanges="ScopeSchemaChanges",
    EmailTemplates="EmailTemplates",
    ProtectedOperation="ProtectedOperation",                           
    Extractor_Rate_Limit = "Extractor_Rate_Limit",
    Extractor_Time_Zone_Specifications = "Extractor_Time_Zone_Specifications",
    Extractor_Semaphores = "Extractor_Semaphores",
    ExtractionEngineSemaphores = "ExtractionEngineSemaphores",
    Extraction_Engine_Semaphores = "Extraction_Engine_Semaphores",
    Report_Data_Source_Filter="Report_Data_Source_Filter",
    TagLocation = "TagLocation",
    FeatureFlags = "FeatureFlags",
    EmailLayouts = "EmailLayouts",
    PlanCategories = "PlanCategories",
    Subscriptions="Subscriptions",
    SubscriptionCategory="SubscriptionCategory",
    Category="Category",
    Extractor = "Extractor",
    Configurations="Configurations",
    CollectionMission="CollectionMission"
}   
