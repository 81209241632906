import React, { Component, Fragment, createRef } from "react";
import { CustomConnectionSemaphoresPostModel } from "./models/custom.connection.semaphores.post.model";
import { EditCustomConnectionSemaphoresModel } from "./models/edit.custom.connection.semaphores.model";
import { DropdownItemModel } from "../../../../shared/models";
import { CustomConnectionsSemaphoresService } from "./services/custom.connection.semaphores.service";
import { CommonService } from "../../../../shared/services/common.service";
import { HttpResponse } from "../../../../core";
import { ShowSuccessMessage } from "../../../../shared/helpers";
import { ButtonType } from "../../../../shared/enums";
import { CustomConnectionSemaphoresTabs } from "./CustomConnectionSemaphoresTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../shared/loaders";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomConnectionFormPopup } from "..";
import { Modal } from 'react-bootstrap';
import { IsAuthorize } from "../../../../shared/authorization/check-access";
import { Role } from "../../../../shared/authorization/enums";
interface IState {
  customconnectionSemaphores?: CustomConnectionSemaphoresPostModel;
  editcustomconnectionSemaphores?: EditCustomConnectionSemaphoresModel;
  id?: number;
  timeout_seconds?: number; 
  size?: number;

  timeSecondError?: string;
  sizeError?: string;

  custom_connection_id?: number;
  customconnectionList?: DropdownItemModel[];
  customconnectionName?: string;
  customconnectionError?: string;

  isShowtext?: string;
  isSave?: string;
  isSaving?: boolean;

  showCustomConnectionForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;
}
export class CustomConnectionSemaphoresForm extends Component<any, IState> {
  //declaring service type
  private service: CustomConnectionsSemaphoresService;
  private commonService: CommonService;

  private networkCall: any;
  private timeSecondRef: any;
  private sizeRef: any;

  constructor(props) {
    super(props);
    this.service = new CustomConnectionsSemaphoresService();
    this.commonService = new CommonService();
    this.state = this.initialState;
    this.timeSecondRef = createRef();
    this.sizeRef = createRef();
    this.updateCustomConnection = this.updateCustomConnection.bind(this);
  }

  initialState: Partial<IState> = {
    customconnectionSemaphores: {
      id: 0,
      custom_connection_id: 0,
      size: 0,
      timeout_seconds: 0,
    },
    id: 0,
    size: 1,
    timeout_seconds: 3600,

    custom_connection_id: 0,
    customconnectionName: "",
    customconnectionList: [],
    customconnectionError: "",
    sizeError: "",
    timeSecondError: "",

    isShowtext: "New",
    isSave: "",
    isSaving: false,

    showCustomConnectionForm:false,
    isNew:false,
    isChildUpdate:false
  };

  componentDidMount() {
    if (this.props.match.params.id > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      event.path[0].id !== "customconnectionName" &&
      event.path[0].id !== "customconnection" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight
    ) {
      this.setState(
        {
          customconnectionList: [],
        },
        () => {}
      );
    }
  }; 

  updateCustomConnection(isChildUpdate,name,id)
  {
    this.setState({isChildUpdate:isChildUpdate,customconnectionName:name,custom_connection_id:id},()=>{      
    })
  }

  handleCloseCustomConnection = () => {
      this.setState({ showCustomConnectionForm: false }, () => {
      });
  }

  handleShowCustomConnection = () => {        
      this.setState({ showCustomConnectionForm: true, isNew:true }, () => {

      });
  }

  handleShowEditCustomConnection = () => {        
      this.setState({ showCustomConnectionForm: true, isNew:false }, () => {

      });
  }

  loadData() {
    this.service
      .editCustomConnectionSemaphores(this.props.match.params.id)
      .then((res: HttpResponse<EditCustomConnectionSemaphoresModel>) => {
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            customconnectionName:
              res.result?.custom_connection == null ? "" : res.result?.custom_connection.text,
            custom_connection_id:
              res.result?.custom_connection == null ? 0 : res.result?.custom_connection.value,
            size: res.result?.size,
            timeout_seconds: res.result?.timeout_seconds,
          });
        }
      })
      .catch((ex) => {
        if (ex.status == 404) {
          this.props.history.push("/custom-connection-semaphore/list");
        }
      });
  }

  postData(customconnectionSemaphores: CustomConnectionSemaphoresPostModel) {
    this.setSavingFlag(true);
    this.service.postCustomConnectionSemaphores(customconnectionSemaphores).then(
      (res: HttpResponse<CustomConnectionSemaphoresPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              customconnectionSemaphores: res.result,
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Custom Connection Semaphore Successfully Created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/custom-connection-semaphore/list");
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/custom-connection-semaphore/custom-connection-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/custom-connection-semaphore/custom-connection-semaphore-form/" +
                    this.state?.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  updateData(customconnectionSemaphores: CustomConnectionSemaphoresPostModel) {
    this.setSavingFlag(true);
    this.service.updateCustomConnectionSemaphores(customconnectionSemaphores).then(
      (res: HttpResponse<CustomConnectionSemaphoresPostModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              customconnectionSemaphores: res.result,
            },
            () => {
              ShowSuccessMessage("Custom Connection Semaphore successfully Updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/custom-connection-semaphore/list");
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/custom-connection-semaphore/custom-connection-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/custom-connection-semaphore/custom-connection-semaphore-form/" +
                    this.state?.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  private getCustomConnections(text: string) {
    this.commonService
      .getCustomConnectionsByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            customconnectionList: res.result,
          });
        }
      });
  }

  //*************** * Custom Connection************************* //

  onCustomConnectionChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getCustomConnections(value);
      }, 600);
    }
    this.setState({
      customconnectionName: value,
      custom_connection_id: value === "" ? 0 : value,
      customconnectionError: "",
    });
  };

  selectedCustomConnection(item) {
    this.setState({
      custom_connection_id: item.value,
      customconnectionName: item.text,
      customconnectionList: [],
    });
  }

  renderCustomConnection = () => {
    if (this.state.customconnectionList?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.customconnectionList?.map((item, index) => (
          <li
            id="custom_connection"
            key={index}
            onClick={() => this.selectedCustomConnection(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  };
  //*************** * Custom Connection************************* //

  handleChange = (event: any) => {
    if (event.target.name == "customconnectionName") {
      this.onCustomConnectionChange(event);
    } else {
      const isCheckbox = event.target.type === "checkbox";
      this.setState({
        [event.target.name]: isCheckbox
          ? event.target.checked
          : event.target.value,
      });
    }
    if (this.timeSecondRef.current.value) {
      this.setState({ timeSecondError: "" });
    }
    if (this.sizeRef.current.value) {
      this.setState({ sizeError: "" });
    }
  };

  validate = () => {
    let customconnectionError = "";
    let timeSecondError = "";
    let sizeError = "";
    if (!this.state.custom_connection_id) {
      customconnectionError = "Custom Connection  can't be blank";
    }

    if (!this.timeSecondRef.current.value) {
      timeSecondError = "Timeout Seconds can't be blank";
    }

    if (!this.sizeRef.current.value) {
      sizeError = "Size can't be blank";
    }

    if (customconnectionError || timeSecondError || sizeError) {
      this.setState({
        customconnectionError: customconnectionError,
        timeSecondError: timeSecondError,
        sizeError:sizeError
      });
      return false;
    }

    if (!customconnectionError || !timeSecondError || !sizeError) {
      customconnectionError = "";
      timeSecondError = "";
      sizeError="";
      this.setState({ customconnectionError: customconnectionError,timeSecondError:timeSecondError,sizeError:sizeError });
      return true;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate)
    {
    const isValid = this.validate();
    let customconnectionSemaphoreData: CustomConnectionSemaphoresPostModel = {
      id: this.state.id,
      custom_connection_id:
        this.state.custom_connection_id === 0 ? null : this.state.custom_connection_id,
      size: this.state.size,
      timeout_seconds: this.state.timeout_seconds,
    };

    if (isValid) {
      if (customconnectionSemaphoreData.id === 0) {
        this.postData(customconnectionSemaphoreData);
      } else {
        this.updateData(customconnectionSemaphoreData);
      }
    }
  }
  };

  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };
  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>
                {this.state.isShowtext} Custom Connection Semaphore{" "}
                {this.props.match.params.id != 0 && (
                  <span>
                    {" "}
                    'AccountSourceOverride #{this.props.match.params.id}'
                  </span>
                )}
              </h4>
            </div>
            <Modal backdrop='static' keyboard={false} size="xl" show={this.state.showCustomConnectionForm} onHide={this.handleCloseCustomConnection}>
                            <CustomConnectionFormPopup  updateCustomConnection={this.updateCustomConnection}  handleCloseCustomConnection={this.handleCloseCustomConnection} isNew={this.state.isNew} id={this.state?.custom_connection_id}/>
                        </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {this.props.match.params.id != 0 && (
                      <CustomConnectionSemaphoresTabs
                        id={this.props.match.params.id}
                        url="/custom-connection-semaphore/edit/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group mb-2 position-relative ">
                          <label>
                            Custom Connection<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group mt-2">
                            <input 
                              autoComplete="off"
                              id="customconnectionName"
                              name="customconnectionName"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.customconnectionName}
                              className={
                                !this.state.customconnectionError
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderCustomConnection()}
                            {IsAuthorize([Role.Dev, Role.TAM, Role.AM, Role.Support]) &&  <div className="input-group-append">
                            <button type="button" className="btn btn-success" onClick={this.handleShowCustomConnection}><i className="fas fa-plus" style={{ color: "white" }}></i></button>
                            {IsAuthorize([Role.Dev, Role.TAM,Role.AM, Role.Support]) &&  <button type="button"  className={`btn btn-primary`} disabled={this.state.custom_connection_id==0}  onClick={this.handleShowEditCustomConnection}><i className="far fa-edit"></i></button>}
                            </div>}
                            <div className="invalid-feedback">
                              {this.state.customconnectionError}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Timeout Seconds
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  We consider a locked semaphore stale after
                                  this many seconds. Should be set to something
                                  larger than what the typical mission is
                                  expected to take. If a lock goes stale, it
                                  automatically gets released.
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            ref={this.timeSecondRef}
                            name="timeout_seconds"
                            value={this.state.timeout_seconds}
                            onChange={this.handleChange}
                            className={
                              !this.state.timeSecondError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.timeSecondError}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="form-group mb-2 position-relative">
                          <label>
                            Size
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                  This is the maximum number of simultaneous
                                  connections we allow for this one custom connection
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            name="size"
                            ref={this.sizeRef}
                            value={this.state.size}
                            onChange={this.handleChange}
                            className={
                              !this.state.sizeError
                                ? "form-control"
                                : "form-control  is-invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.sizeError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/custom-connection-semaphore/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="200px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
