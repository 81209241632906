import React, { Fragment } from "react";
import "./navigation.scss";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../../redux/navigation";
import $ from "jquery";
import { setTimeout } from "timers";
import { Role } from "../../shared/authorization/enums";
import { IsAuthorize } from "../../shared/authorization/check-access";
interface IProps {
  current_route: string;
  ChangePage: Function;
  ChangeFilters: Function;
}

export class NavigationBar extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.changeCurrentData = this.changeCurrentData.bind(this);
  }

  changeCurrentData() {
    this.props.ChangePage(1);
    this.props.ChangeFilters([]);
  }
  componentDidMount() {
    setTimeout(() => {
      let winObj = window as any;
      if (winObj.InitSidebar) {
        winObj.InitSidebar();
      }
      if (winObj.toggleSidebar) {
        $("[data-toggle='sidebar']").click(winObj.toggleSidebar);
      }
      $(".menu-toggle").click(winObj.toggleNavBarS);
    }, 1000);
  }

  setCurrentRoutClass() {
    setTimeout(() => {
      $(".activenav")
        .parents(".dropdown")
        .addClass("expandtoggle")
        .addClass("toggled-s");
      $(".expandtoggle").find("ul:eq(0)").show();
    }, 0);
  }

  render() {
    this.setCurrentRoutClass();
    return (
      <Fragment>
        <div className="main-sidebar sidebar-style-2">
          <aside id="sidebar-wrapper">
            <div className="sidebar-brand">
              <Link to="/">
                <img
                  src={require("./logo.png")}
                  className="header-logo img-fluid"
                  alt="STAQ"
                />
              </Link>
            </div>
            <ul className="sidebar-menu">
              <li className="dropdown main-menu-item expandtoggle toggled-s">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="far fa-user"></i>
                  <span>Accounts</span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className={
                      this.props.current_route.includes("/account/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink to="/account/list" className="nav-link" exact>
                      Account List
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes("/account-domain/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/account-domain/list"
                      className="nav-link"
                      exact
                    >
                      Account Domains
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes(
                        "/account-source-override/"
                      )
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/account-source-override/list"
                      className="nav-link"
                      exact
                    >
                      Account Source Overrides
                    </NavLink>
                  </li>

                  <li className="dropdown menutoggle-s">
                    <a className="has-dropdown" href={void 0}>
                      <span>Connections</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          this.props.current_route.includes("/connection/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/connection/list"
                          className="nav-link"
                          exact
                        >
                          Connection List
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes(
                            "/connection-semaphore/"
                          )
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/connection-semaphore/list"
                          className="nav-link"
                          exact
                        >
                          Connection Semaphores
                        </NavLink>
                      </li>
                      <li className="dropdown menutoggle-s">
                        <a className="has-dropdown" href={void 0}>
                          <span>Platforms</span>
                        </a>
                        <ul className="dropdown-menu">
                          <li
                            className={
                              this.props.current_route.includes("/platform/")
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/platform/list"
                              className="nav-link"
                              exact
                            >
                              <span>Platforms List</span>
                            </NavLink>
                          </li>
                          <li className="dropdown menutoggle-s">
                            <a className="has-dropdown" href={void 0}>
                              <span>Extractors</span>
                            </a>
                            <ul className="dropdown-menu">
                              {IsAuthorize([
                                Role.TAM,
                                Role.Dev,
                                Role.Support,
                              ]) && (
                                <li
                                  className={
                                    this.props.current_route.includes(
                                      "/extractor/"
                                    )
                                      ? "activenav"
                                      : ""
                                  }
                                  onClick={this.changeCurrentData}
                                >
                                  <NavLink
                                    to="/extractor/list"
                                    className="nav-link"
                                    exact
                                  >
                                    <span>Extractors List</span>
                                  </NavLink>
                                </li>
                              )}
                              {IsAuthorize([
                                Role.TAM,
                                Role.Dev,
                                Role.Support,
                              ]) && (
                                <li
                                  className={
                                    this.props.current_route.includes(
                                      "/extractor-rate-limit/"
                                    )
                                      ? "activenav"
                                      : ""
                                  }
                                  onClick={this.changeCurrentData}
                                >
                                  <NavLink
                                    to="/extractor-rate-limit/list"
                                    className="nav-link"
                                    exact
                                  >
                                    <span>Extractor Rate Limits</span>
                                  </NavLink>
                                </li>
                              )}
                              {IsAuthorize([
                                Role.TAM,
                                Role.Dev,
                                Role.Support,
                              ]) && (
                                <li
                                  className={
                                    this.props.current_route.includes(
                                      "/extractor-semaphore/"
                                    )
                                      ? "activenav"
                                      : ""
                                  }
                                  onClick={this.changeCurrentData}
                                >
                                  <NavLink
                                    to="/extractor-semaphore/list"
                                    className="nav-link"
                                    exact
                                  >
                                    <span>Extractor Semaphores</span>
                                  </NavLink>
                                </li>
                              )}
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/extractor-time-zone-specification/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/extractor-time-zone-specification/list"
                                  className="nav-link"
                                  exact
                                >
                                  <span>Time Zone Specification</span>
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown menutoggle-s">
                    <a className="has-dropdown" href={void 0}>
                      <span>Custom Connections</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          this.props.current_route.includes(
                            "/custom-connection/"
                          )
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/custom-connection/list"
                          className="nav-link"
                          exact
                        >
                          Custom Connection List
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes(
                            "/custom-connection-semaphore/"
                          )
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/custom-connection-semaphore/list"
                          className="nav-link"
                          exact
                        >
                          Custom Connection Semaphores
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes("/inbound-file/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/inbound-file/list"
                          className="nav-link"
                          exact
                        >
                          Inbound Files
                        </NavLink>
                      </li>
                      {IsAuthorize([
                        Role.TAM,
                        Role.Dev,
                        Role.Sales,
                        Role.AM,
                      ]) && (
                        <li className="dropdown menutoggle-s">
                          <a className="has-dropdown" href={void 0}>
                            <span>Extraction Engines</span>
                          </a>
                          <ul className="dropdown-menu">
                            <li
                              className={
                                this.props.current_route.includes(
                                  "/extraction-engine/"
                                )
                                  ? "activenav"
                                  : ""
                              }
                              onClick={this.changeCurrentData}
                            >
                              <NavLink
                                to="/extraction-engine/list"
                                className="nav-link"
                                exact
                              >
                                Extraction Engines List
                              </NavLink>
                            </li>
                            <li
                              className={
                                this.props.current_route.includes(
                                  "/extraction-engine-semaphores/"
                                )
                                  ? "activenav"
                                  : ""
                              }
                              onClick={this.changeCurrentData}
                            >
                              <NavLink
                                to="/extraction-engine-semaphores/list"
                                className="nav-link"
                                exact
                              >
                                Extraction Engines Semaphores List
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      )}

                      <li className="dropdown menutoggle-s">
                        <a className="has-dropdown" href={void 0}>
                          <span>Inbound Email Addresses</span>
                        </a>
                        <ul className="dropdown-menu">
                          {IsAuthorize([
                            Role.TAM,
                            Role.Dev,
                            Role.AM,
                            Role.Support,
                            Role.Manager,
                          ]) && (
                            <li
                              className={
                                this.props.current_route.includes(
                                  "/inbound-email-address/"
                                )
                                  ? "activenav"
                                  : ""
                              }
                              onClick={this.changeCurrentData}
                            >
                              <NavLink
                                to="/inbound-email-address/list"
                                className="nav-link"
                                exact
                              >
                                Inbound Email Addresses List
                              </NavLink>
                            </li>
                          )}
                          <li
                            className={
                              this.props.current_route.includes(
                                "/inbound-email-message/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/inbound-email-message/list"
                              className="nav-link"
                              exact
                            >
                              Inbound Email Messages
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      {/* <li><a className="nav-link" href={void(0)}>Flow File Tracks</a></li> */}
                    </ul>
                  </li>
                  <li className="dropdown  menutoggle-s  expandtoggle toggled-s">
                    <a href={void 0} className="has-dropdown">
                      <span>Users</span>
                    </a>
                    {/* <a className="has-dropdown" href={void(0)}><span>Users</span></a> */}
                    <ul className="dropdown-menu">
                      <li
                        className={
                          this.props.current_route.includes("/user/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink to="/user/list" exact>
                          User List
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes("/dashboard/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/dashboard/list"
                          className="nav-link"
                          exact
                        >
                          Dashboards
                        </NavLink>
                      </li>
                      <li className="dropdown menutoggle-s  expandtoggle toggled-s">
                        <a className=" has-dropdown" href={void 0}>
                          <span>Reports</span>
                        </a>
                        <ul className="dropdown-menu">
                          <li
                            className={
                              this.props.current_route.includes("/report/")
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/report/list"
                              className="nav-link"
                              exact
                            >
                              Report List
                            </NavLink>
                          </li>
                          <li
                            className={
                              this.props.current_route.includes(
                                "/installed-report-application/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/installed-report-application/list"
                              className="nav-link"
                              exact
                            >
                              Installed Applications
                            </NavLink>
                          </li>
                          <li
                            className={
                              this.props.current_route.includes(
                                "/report-change-history/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/report-change-history/list"
                              className="nav-link"
                              exact
                            >
                              {" "}
                              Report Change Histories
                            </NavLink>
                          </li>
                          <li className="dropdown menutoggle-s">
                            {" "}
                            <a className=" has-dropdown" href={void 0}>
                              <span>Report Columns</span>
                            </a>
                            <ul className="dropdown-menu">
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-column/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-column/list"
                                  className="nav-link"
                                  exact
                                >
                                  {" "}
                                  Report Columns
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-column-field/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-column-field/list"
                                  className="nav-link"
                                  exact
                                >
                                  {" "}
                                  Report Column Fields
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-column-filter/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-column-filter/list"
                                  className="nav-link"
                                  exact
                                >
                                  {" "}
                                  Report Column Filters
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown menutoggle-s">
                            {" "}
                            <a className=" has-dropdown" href={void 0}>
                              <span>Report Filters</span>
                            </a>
                            <ul className="dropdown-menu">
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-filter/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-filter/list"
                                  className="nav-link"
                                  exact
                                >
                                  {" "}
                                  Report Filters
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-data-source-filter/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-data-source-filter/list"
                                  className="nav-link"
                                  exact
                                >
                                  {" "}
                                  Report Data Source
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              this.props.current_route.includes(
                                "/report-run-schedule/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/report-run-schedule/list"
                              className="nav-link"
                              exact
                            >
                              Report Run Schedules
                            </NavLink>
                          </li>
                          <li className="dropdown menutoggle-s">
                            <a className=" has-dropdown" href={void 0}>
                              <span>Report Views</span>
                            </a>
                            <ul className="dropdown-menu">
                              <li
                                className={
                                  this.props.current_route.includes(
                                    "/report-view/"
                                  )
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/report-view/list"
                                  className="nav-link"
                                  exact
                                >
                                  Report Views
                                </NavLink>
                              </li>
                              <li
                                className={
                                  this.props.current_route.includes("/applet/")
                                    ? "activenav"
                                    : ""
                                }
                                onClick={this.changeCurrentData}
                              >
                                <NavLink
                                  to="/applet/list"
                                  className="nav-link"
                                  exact
                                >
                                  Applets
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              this.props.current_route.includes(
                                "/source-report/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/source-report/list"
                              className="nav-link"
                              exact
                            >
                              {" "}
                              Source Reports
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li className="dropdown">
            <a href={void(0)} className="nav-link"><i className="fas fa-bullhorn"></i> <span>Announcements</span></a>
          </li> */}
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-calendar-alt"></i>
                  <span>Extraction Scheduling Recipes</span>
                </a>
                <ul className="dropdown-menu">
                  {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                    <li
                      className={
                        this.props.current_route.includes(
                          "/extraction-scheduling-recipe/"
                        )
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/extraction-scheduling-recipe/list"
                        className="nav-link"
                        exact
                      >
                        {" "}
                        Extraction Scheduling Recipes List
                      </NavLink>
                    </li>
                  )}
                  <li
                    className={
                      this.props.current_route.includes(
                        "/extraction-scheduling-recipe-change/"
                      )
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/extraction-scheduling-recipe-change/list"
                      exact
                    >
                      Extraction Scheduling Recipe Changes
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-chalkboard"></i>
                  <span>Applications</span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className={
                      this.props.current_route.includes("/application/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink to="/application/list" className="nav-link" exact>
                      Applications List
                    </NavLink>
                  </li>
                  {IsAuthorize([
                    Role.TAM,
                    Role.Dev,
                    Role.AM,
                    Role.Support,
                    Role.Manager,
                  ]) && (
                    <li
                      className={
                        this.props.current_route.includes(
                          "/invocation-schedule/"
                        )
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/invocation-schedule/list"
                        className="nav-link"
                        exact
                      >
                        <span>Invocation Schedules</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-project-diagram"></i>
                  <span>Schemas</span>
                </a>
                <ul className="dropdown-menu">
                  {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                    <li
                      className={
                        this.props.current_route.includes("/schema/")
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink to="/schema/list" className="nav-link" exact>
                        Schemas List{" "}
                      </NavLink>
                    </li>
                  )}
                  <li className="dropdown menutoggle-s">
                    <a className=" has-dropdown" href={void 0}>
                      <span>Scopes</span>
                    </a>
                    <ul className="dropdown-menu">
                      {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                        <li
                          className={
                            this.props.current_route.includes("/scope/")
                              ? "activenav"
                              : ""
                          }
                          onClick={this.changeCurrentData}
                        >
                          <NavLink to="/scope/list" className="nav-link" exact>
                            Scopes List
                          </NavLink>
                        </li>
                      )}
                      <li className="dropdown menutoggle-s">
                        <a className=" has-dropdown" href={void 0}>
                          <span>Fields</span>
                        </a>
                        <ul className="dropdown-menu">
                          <li
                            className={
                              this.props.current_route.includes("/field/")
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/field/list"
                              className="nav-link"
                              exact
                            >
                              Fields List{" "}
                            </NavLink>
                          </li>
                          <li
                            className={
                              this.props.current_route.includes(
                                "/field-classification/"
                              )
                                ? "activenav"
                                : ""
                            }
                            onClick={this.changeCurrentData}
                          >
                            <NavLink
                              to="/field-classification/list"
                              className="nav-link"
                              exact
                            >
                              Fields Classifications{" "}
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes("/scope-schema-change/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/scope-schema-change/list"
                      className="nav-link"
                      exact
                    >
                      Scope Schema Changes
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes(
                        "/scope-field-schema-change/"
                      )
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/scope-field-schema-change/list"
                      className="nav-link"
                      exact
                    >
                      Scope Field Schema Changes
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-database"></i>
                  <span>Database Engine</span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className={
                      this.props.current_route.includes("/database-engine/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink to="/database-engine/list" exact>
                      Database Engine List
                    </NavLink>
                  </li>
                  <li className="dropdown menutoggle-s">
                    <a className=" has-dropdown" href={void 0}>
                      <span>Data Engines</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          this.props.current_route.includes("/data-engine/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink to="/data-engine/list" exact>
                          Data Engine List
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes("/data-engine-job/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink to="/data-engine-job/list" exact>
                          Data Engine Jobs
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li onClick={this.changeCurrentData}><NavLink to="" className="nav-link" exact>Resqueue Statuses</NavLink></li> */}
                </ul>
              </li>
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-rss"></i>
                  <span>External Roles and Communication</span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className={
                      this.props.current_route.includes("/email-template/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/email-template/list"
                      className="nav-link"
                      exact
                    >
                      Email Templates
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes("/email-layout/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink to="/email-layout/list" className="nav-link" exact>
                      Email Layouts
                    </NavLink>
                  </li>
                  {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                    <li
                      className={
                        this.props.current_route.includes("/feature-flag/")
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/feature-flag/list"
                        className="nav-link"
                        exact
                      >
                        Feature Flags
                      </NavLink>
                    </li>
                  )}
                  <li
                    className={
                      this.props.current_route.includes("/plan-category/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/plan-category/list"
                      className="nav-link"
                      exact
                    >
                      Plan Categories
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes("/role/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink to="/role/list" className="nav-link" exact>
                      Roles
                    </NavLink>
                  </li>
                  <li className="dropdown menutoggle-s">
                    <a className=" has-dropdown" href={void 0}>
                      <span>Subscriptions</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className={
                          this.props.current_route.includes("/subscription/")
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/subscription/list"
                          className="nav-link"
                          exact
                        >
                          Subscriptions List
                        </NavLink>
                      </li>
                      <li
                        className={
                          this.props.current_route.includes(
                            "/subscription-category/"
                          )
                            ? "activenav"
                            : ""
                        }
                        onClick={this.changeCurrentData}
                      >
                        <NavLink
                          to="/subscription-category/list"
                          className="nav-link"
                          exact
                        >
                          Subscription Categories
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown main-menu-item">
                <a href={void 0} className="menu-toggle nav-link has-dropdown">
                  <i className="fas fa-file-image"></i>
                  <span>Images</span>
                </a>
                <ul className="dropdown-menu">
                  <li
                    className={
                      this.props.current_route.includes("/customer-favicon/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/customer-favicon/list"
                      className="nav-link"
                      exact
                    >
                      Customer Favicons
                    </NavLink>
                  </li>
                  <li
                    className={
                      this.props.current_route.includes("/customer-logo/")
                        ? "activenav"
                        : ""
                    }
                    onClick={this.changeCurrentData}
                  >
                    <NavLink
                      to="/customer-logo/list"
                      className="nav-link"
                      exact
                    >
                      Customer Logos
                    </NavLink>
                  </li>
                </ul>
              </li>
              {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                <li className="dropdown main-menu-item">
                  <a
                    href={void 0}
                    className="menu-toggle nav-link has-dropdown"
                  >
                    <i className="fas fa-tags"></i>
                    <span>Tag Matcher</span>
                  </a>
                  <ul className="dropdown-menu">
                    <li
                      className={
                        this.props.current_route.includes("/tag-matcher/")
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/tag-matcher/list"
                        className="nav-link"
                        exact
                      >
                        Tag Matcher List
                      </NavLink>
                    </li>
                    <li
                      className={
                        this.props.current_route.includes("/tag-location/")
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/tag-location/list"
                        className="nav-link"
                        exact
                      >
                        Tag Locations
                      </NavLink>
                    </li>
                    <li
                      className={
                        this.props.current_route.includes(
                          "/tag-location-category/"
                        )
                          ? "activenav"
                          : ""
                      }
                      onClick={this.changeCurrentData}
                    >
                      <NavLink
                        to="/tag-location-category/list"
                        className="nav-link"
                        exact
                      >
                        Tag Location Categories
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              <li
                className={
                  this.props.current_route.includes("/category/")
                    ? "activenav without-dropdown main-menu-item"
                    : "without-dropdown main-menu-item"
                }
                onClick={this.changeCurrentData}
              >
                <NavLink to="/category/list" className="nav-link" exact>
                  <i className="fas fa-tasks"></i> <span>Categories</span>
                </NavLink>
              </li>
              <li
                className={
                  this.props.current_route.includes("/admin/")
                    ? "activenav without-dropdown main-menu-item"
                    : "without-dropdown main-menu-item"
                }
                onClick={this.changeCurrentData}
              >
                <NavLink to="/admin/list" className="nav-link" exact>
                  <i className="far fa-address-book"></i>{" "}
                  <span>STAQ Contacts</span>
                </NavLink>
              </li>
              <li
                className={
                  this.props.current_route.includes("/staq-event/")
                    ? "activenav without-dropdown main-menu-item"
                    : "without-dropdown main-menu-item"
                }
                onClick={this.changeCurrentData}
              >
                <NavLink to="/staq-event/list" className="nav-link" exact>
                  <i className="far fa-calendar-check"></i>{" "}
                  <span>STAQ Events</span>
                </NavLink>
              </li>
              {IsAuthorize([Role.TAM, Role.Dev, Role.Support]) && (
                <li
                  className={
                    this.props.current_route.includes("/protected-operation/")
                      ? "activenav without-dropdown main-menu-item"
                      : "without-dropdown main-menu-item"
                  }
                  onClick={this.changeCurrentData}
                >
                  <NavLink
                    to="/protected-operation/list"
                    className="nav-link"
                    exact
                  >
                    <i className="fas fa-shield-alt"></i>{" "}
                    <span>Protected Operations</span>
                  </NavLink>
                </li>
              )}
              <li
                className={
                  this.props.current_route.includes("/configuration/")
                    ? "activenav without-dropdown main-menu-item"
                    : "without-dropdown main-menu-item"
                }
                onClick={this.changeCurrentData}
              >
                <NavLink to="/configuration/list" className="nav-link" exact>
                  <i className="fas fa-cogs"></i> <span>Configurations</span>
                </NavLink>
              </li>
            </ul>
          </aside>
        </div>
      </Fragment>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//       current_route: state.name
//   }
// }

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
