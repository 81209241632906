import React, { Component, Fragment } from "react";
import { ExtractionEngineSemaphoresService } from "./services/extraction.engine.semaphores.service";
import { EditExtractionEngineSemaphoresModel } from "./models/edit.extraction.engine.semaphores.model";
import { HttpResponse } from "../../../../../core";
import {
  HandleNotFoundResponse,
  ShowSuccessMessage,
} from "../../../../../shared/helpers";
import {
  NotFoundResponseArea,
  ButtonType,
} from "../../../../../shared/enums";
import { PostExtractionEngineSemaphoresModel } from "./models";
import { ExtractionEngineSemaphoresTabs } from "./ExtractionEngineSemaphoresTabs";
import { Link } from "react-router-dom";
import { Loader } from "../../../../../shared/loaders";
import { DropdownItemModel } from "../../../../../shared/models";
import { CommonService } from "../../../../../shared/services/common.service";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExtractionEnginesFormPopup } from "../ExtractionEnginesFormPopup";
import { IsAuthorize } from "../../../../../shared/authorization/check-access";
import { Role } from "../../../../../shared/authorization/enums";

interface IState {
  id?: number;
  extraction_engine_id?: number;
  size?: number;
  timeout_seconds?: number;
  isShowtext?: string;
  searchText?: string;
  isSave?: string;
  isSaving?: boolean;
  extraction_error?: string;
  extraction_engine_name_list?: DropdownItemModel[];
  extraction_engine_name?: string;
  showExtractorForm?: boolean;
  isNew?: boolean;
  isChildUpdate?: boolean;
}

export class ExtractionEngineSemaphoresForm extends Component<any, IState> {
  private service: ExtractionEngineSemaphoresService;
  private commonService: CommonService;
  private networkCall: any;

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.service = new ExtractionEngineSemaphoresService();
    this.commonService = new CommonService();
    this.updateExtractionEngine = this.updateExtractionEngine.bind(this)
  }

  //Initial State to get the data for the edit functionality from the API
  initialState: Partial<IState> = {
    id: 0,
    extraction_engine_id: 0,
    size: 1,
    timeout_seconds: 3600,
    isShowtext: "New",
    searchText: "",
    isSave: "",
    isSaving: false,
    extraction_error: "",
    extraction_engine_name_list: [],
    extraction_engine_name: "",
    showExtractorForm: false,
    isNew: false,
    isChildUpdate: false,
  };

  updateExtractionEngine(isChildUpdate, name, id) {
    this.setState({
      isChildUpdate: isChildUpdate,
      extraction_engine_name: name,
      extraction_engine_id: id,
    });
  }

  handleExtractorClose = () => {
    this.setState({ showExtractorForm: false }, () => {});
  };

  handleShowExtractor = () => {
    this.setState({ showExtractorForm: true, isNew: true }, () => {});
  };

  handleShowEditExtractor = () => {
    this.setState(
      { showExtractorForm: true, isNew: false },
      () => {}
    );
  };

  // To the load the initial data coming from API for the Edit functionality.
  componentDidMount() {
    if (Number(this.props.match.params.id) > 0) {
      this.setState(
        { id: this.props.match.params.id, isShowtext: "Edit" },
        () => {
          this.loadData();
        }
      );
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (event.path[0].id !== "extraction_engine_name" && event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight) {
      this.setState(
        {
          extraction_engine_name_list: [],
        },
        () => {}
      );
    }
  };

  private getExtractorByName(text: string) {
    this.commonService
      .getExtractorByName(text)
      .then((res: HttpResponse<DropdownItemModel[]>) => {
        if (res) {
          this.setState({
            extraction_engine_name_list: res.result,
          });
        }
      });
  }

  // Function for loading the data in the intial phase.
  loadData() {
    this.setSavingFlag(true);
    this.service
      .editExtractionEngineSemaphores(this.props.match.params.id)
      .then((res: HttpResponse<EditExtractionEngineSemaphoresModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState({
            id: res.result?.id,
            extraction_engine_id:
              res.result?.extraction_engine_id === null ? 0 : res.result?.extraction_engine_id,
              extraction_engine_name:
              res.result?.extraction_engine_name === null ? "" : res.result?.extraction_engine_name,
            size:
              res.result?.size === null ? 0 : res.result?.size,
            timeout_seconds:
              res.result?.timeout_seconds === null ? 0 : res.result?.timeout_seconds
          });
        }
      })
      .catch((ex) => {
        HandleNotFoundResponse(
          ex,
          NotFoundResponseArea.Extraction_Engine_Semaphores,
          this.props
        );
      });
  }

  validate = () => {
    let extraction_error = "";

    if (!this.state.extraction_engine_id && this.state.isSave) {
      extraction_error = "Extractor Cant be blank";
    }
    if (extraction_error) {
      this.setState({
        extraction_error: extraction_error,
      });
      return false;
    } else {
      extraction_error = "";
      this.setState({
        extraction_error: extraction_error,
      });
      return true;
    }
  };

  onExtractorChange = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      if (this.networkCall) {
        clearTimeout(this.networkCall);
      }
      this.networkCall = setTimeout(() => {
        this.getExtractorByName(value);
      }, 600);
    }
    this.setState({
      extraction_engine_id: value === "" ? 0 : value,
      extraction_engine_name: value,
      extraction_error: "",
    });
  };

  handleChange = (event: any) => {
    if (event.target.name == "extraction_engine_name") {
      this.onExtractorChange(event);
    }
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if(!this.state.isChildUpdate){
    const isValid = this.validate();
    let payload = {
      id: this.state.id,
      extraction_engine_id: this.state.extraction_engine_id,
      size: this.state.size,
      timeout_seconds: this.state.timeout_seconds
    };
    if (isValid) {
      if (payload.id == 0) {
        this.postData(payload);
      } else {
        this.updateData(payload);
      }
    }
  }
  };

  postData(extraction_engine: PostExtractionEngineSemaphoresModel) {
    this.setSavingFlag(true);
    this.service.postExtractionEngineSemaphores(extraction_engine).then(
      (res: HttpResponse<PostExtractionEngineSemaphoresModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              ShowSuccessMessage("Extraction Engine semaphore successfully created.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/extraction-engine-semaphore/list");
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/extraction-engine-semaphore/extraction-engine-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/extraction-engine-semaphore/extraction-engine-semaphore-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  //Function to update the data
  updateData(extraction_engine: PostExtractionEngineSemaphoresModel) {
    this.setSavingFlag(true);
    this.service.updateExtractionEngineSemaphores(extraction_engine).then(
      (res: HttpResponse<PostExtractionEngineSemaphoresModel>) => {
        this.setSavingFlag(false);
        if (res && res.result) {
          this.setState(
            {
              id: res.result.id,
            },
            () => {
              //Showing the message along with changing the route according to the button clicked.
              ShowSuccessMessage("Extraction Engine semaphore successfully updated.");
              if (this.state.isSave === ButtonType.Save) {
                this.props.history.push("/extraction-engine-semaphore/list");
                this.setState(this.initialState);
              }
              if (this.state.isSave === ButtonType.SaveAndAddAnother) {
                this.setState(this.initialState);
                this.props.history.push(
                  "/extraction-engine-semaphore/extraction-engine-semaphore-form/0"
                );
              }
              if (this.state.isSave === ButtonType.SaveAndEdit) {
                this.props.history.push(
                  "/extraction-engine-semaphore/extraction-engine-semaphore-form/" +
                    this.state.id
                );
                this.setState({ isShowtext: "Edit" });
              }
            }
          );
        }
      },
      () => {
        this.setSavingFlag(false);
      }
    );
  }

  selectedAccount(item) {
    this.setState({
      extraction_engine_id: item.value,
      extraction_engine_name: item.text,
      extraction_engine_name_list: [],
    });
  }

  // Function to check the type of save functionality , in our case save and save & Edit.
  handleClick = (event) => {
    this.setState({ isSave: event.target.value,isChildUpdate:false })
  };

  private setSavingFlag(saving: boolean) {
    this.setState({ isSaving: saving });
  }

  renderExtractors() {
    if (this.state.extraction_engine_name_list?.length === 0) {
      return null;
    }
    return (
      <ul className="list-unstyled auto-suggest">
        {this.state.extraction_engine_name_list?.map((item, index) => (
          <li
            id="extraction_engine_name"
            key={index}
            onClick={() => this.selectedAccount(item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" value={this.state.id} />
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5>
                {this.state.isShowtext} Extractor Semaphore
                {this.state.isShowtext === "Edit" && (
                  <span> 'ExtractionEngineSemaphore #{this.state.id}'</span>
                )}
              </h5>
            </div> 
            <Modal
              backdrop="static"
              keyboard={false}
              size="lg"
              show={this.state.showExtractorForm}
              onHide={this.handleExtractorClose}
            >
              <ExtractionEnginesFormPopup
                updateExtractionEngine={
                  this.updateExtractionEngine
                }
                handleClose={this.handleExtractorClose}
                isNew={this.state.isNew}
                id={this.state?.extraction_engine_id}
              />
            </Modal>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {Number(this.props.match.params.id) !== 0 && (
                      <ExtractionEngineSemaphoresTabs
                        id={this.props.match.params.id}
                        url="/extraction-engine-semaphore/extraction-engine-semaphore-form/"
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Extractor<span className=" text-danger">*</span>
                          </label>
                          <div className="input-group ">
                            <input
                              list="extraction_engine_name"
                              autoComplete="off"
                              id="extraction_engine_name"
                              name="extraction_engine_name"
                              type="text"
                              onChange={this.handleChange}
                              value={this.state.extraction_engine_name}
                              className={
                                !this.state.extraction_error
                                  ? "form-control"
                                  : "form-control  is-invalid"
                              }
                            />
                            {this.renderExtractors()}
                            {IsAuthorize([Role.Dev]) && <div className="input-group-append">
                              <button type="button" className="btn btn-success" onClick={this.handleShowExtractor}>
                                <i
                                  className="fas fa-plus"
                                  style={{ color: "white" }}
                                ></i>
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                disabled={this.state.extraction_engine_id == 0}
                                onClick={this.handleShowEditExtractor}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                            </div>}
                            <div className="invalid-feedback">
                              {this.state.extraction_error}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Size 
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                This is the maximum number of simultaneous collection tasks we allow, for any connections that use this extraction_engine
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            maxLength={255}
                            name="size"
                            className="form-control"
                            value={this.state.size}
                            onChange={this.handleChange}
                          />
                           
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Timeout seconds
                          <OverlayTrigger
                              overlay={
                                <Tooltip id="button-tooltip">
                                We consider a locked semaphore stale after this many seconds. Should be set to something larger than what the typical collection task is expected to take. If a lock goes stale, it automatically gets released.
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-outline-dark border-0 btn-sm ml-1">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </label>
                          <input
                            type="number"
                            maxLength={255}
                            name="timeout_seconds"
                            className="form-control"
                            value={this.state.timeout_seconds}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-4">
              {!this.state.isSaving && (
                <Fragment>
                  <Link
                    type="button"
                    className="btn btn-lg btn-default"
                    to="/extraction-engine-semaphore/list"
                  >
                    {ButtonType.Cancel}
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    onClick={this.handleClick}
                    value={ButtonType.Save}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndAddAnother}
                    onClick={this.handleClick}
                  />
                  <input
                    type="submit"
                    className="btn btn-lg btn-primary  ml-3"
                    value={ButtonType.SaveAndEdit}
                    onClick={this.handleClick}
                  />
                </Fragment>
              )}
              <Loader
                loading={this.state.isSaving}
                marginBottom="0px"
                marginTop="8px"
                float="center"
                width="368px"
              ></Loader>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}
